.sidebar--menu.open {
  transform: translate(0);
  box-shadow: -8px 10px 25px black;
}

.sidebar--menu {
  background-color: #ffffff;
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-250px);
  transition: 0.5s ease;
  padding: 20px 10px;
  z-index: 5;
}

.sidebar--menu-items-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 200px;
  padding: 0 10px;
}

.sidebar--menu-items {
  display: flex;
  align-items: center;
  gap: 20px;
  border: none;
  background-color: #ffffff;
  font-size: 16px;
}

.sidebar--profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sidebar--signout-btn {
  border: none;
  background-color: #ffffff;
  color: #8d8d8d;
}
.sidebar--signout-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.sidebar--background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.5s ease;
}

.sidebar--sign-out-main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sidebar--sign-out-main button {
  border-radius: 10px;
  background-color: #000000;
  color: #ffffff;
  width: 200px;
  height: 60px;
}

.sidebar--sign-out-main button:hover {
  background-color: #333333;
}
.sidebar--sign-out-main button:active {
  background-color: #505050;
}
.sidebar--sign-out-main a:hover {
  color: #ffffff;
  background-color: #000000;
}

.sidebar--manager-portal-btn {
  font-weight: bold;
}
