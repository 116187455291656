.address--modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.address--modal > button {
  border: none;
  height: 40px;
  border-radius: 10px;
  background-color: #000000;
  color: white;
}

.address--modal > button:hover {
  background-color: #333333;
}
.address--modal > button:active {
  background-color: #505050;
}

.address--modal > button:disabled {
  background-color: #505050;
  cursor: not-allowed;
}

.address--modal > input,
select {
  border: none;
  height: 40px;
  border-radius: 10px;
  background-color: #eeeeee;
  color: black;
  padding: 10px;
}
