.details--img-header {
  width: 100%;
  height: 160px;
  object-fit: cover;
  margin-bottom: 10px;
}

.details--restaurant-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 40px;
  margin: 20px 0;
  gap: 10px;
}

.details--restaurant-main > p {
  font-size: 14px;
}

#heart {
  color: black;
}

.details--restaurant-main span > button {
  border: none;
  background: #ffffff;
  text-decoration: underline;
}

.details--restaurant-main a {
  color: #000000;
  text-decoration: underline;
}

.details--restaurant-main p.details--hours {
  font-size: 12px;
  color: #9a9a9a;
}

.back-to-main {
  margin-left: 40px;
}

.back-to-main:hover {
  cursor: pointer;
  text-decoration: underline;
}

MenuItem {
  margin-top: 25px;
}

.details--manage-btn {
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  border-radius: 20px;
}

.details--manage-btn:hover {
  background-color: #333333;
}

.details--manage-btn:active {
  background-color: #505050;
}

.details--header-manage {
  display: flex;
  align-items: center;
  gap: 10px;
}
