#logo {
  color: #ffd900;
  letter-spacing: 2px;
}

.navbar--main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1920px;
  gap: 2rem;
  height: 100px;
  padding: 0 40px;
  position: relative;
  z-index: 1;
  background-color: #ffffff;
}

.navbar--main a,
a:visited {
  color: #000000;
}

.navbar--main > div {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.navbar--menuicon {
  grid-column: 1;
}

.navbar--home {
  grid-column: 3 / 4;
}

.navbar--deliver-pickup {
  border-radius: 30px;
  background-color: #eeeeee;
  width: 300px;
  padding: 0;
  justify-content: space-between;
  cursor: pointer;
}

.del-pick-highlight {
  border-radius: 30px;
  background-color: #ffffff;
  padding: 10px 15px;
}

.navbar--location {
  min-width: 200px;
  width: 250px;
  border-radius: 30px;
  background-color: #eeeeee;
  justify-content: center;
  cursor: pointer;
}

.navbar--location > button {
  width: 200px;
  height: 40px;
  border: none;
  background-color: transparent;
  border-radius: 20px;
  color: #000000;
}

.navbar--location > button:hover,
button:active {
  background-color: transparent;
}

.navbar--searchbar {
  min-width: 200px;
  border-radius: 30px;
  background-color: #eeeeee;
  width: 100%;
}

.navbar--searchbar > input {
  border: none;
  background-color: #eeeeee;
  width: 100%;
  padding-left: 10px;
  border-radius: 30px;
  height: 50px;
  outline: none;
}

.navbar--shopping-btn {
  border: none;
  background-color: #000000;
  color: white;
  height: 50px;
  border-radius: 30px;
  width: 12rem;
  min-width: 100px;
}

.navbar--shopping-btn:hover {
  background-color: #333333;
}

.navbar--shopping-btn:active {
  background-color: #505050;
}
