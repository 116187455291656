/* TODO Add site wide styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}
body {
  max-width: 1920px;
  margin: 0 auto;
}

dialog {
  margin: auto;
}

a {
  text-decoration: none;
  color: black;
}

li {
  list-style: none;
}

button {
  cursor: pointer;
  border: none;
}

.modal-button:hover {
  background-color: #333333;
}

.modal-button:active {
  background-color: #505050;
}

.errors {
  color: red;
}
