/* Menu Item Detail Page */
.menu-item-detail--container {
  padding-left: 40px;
  padding-right: 40px;
  min-height: 100vh;
}

.menuItemTile {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "detail-img   detail-name"
    "detail-img   detail-price"
    "detail-img   detail-description"
    "detail-img   ."
    "detail-img   .";
}

.item-detail-img--container {
  grid-area: detail-img;
}

.itemDetailImg {
  /* grid-area: detail-img; */
  width: 492px;
  height: 492px;
  object-fit: cover;
  object-position: center;
}

#item-detail--no-image {
  grid-area: detail-img;
  background-color: #eeeeee;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 492px;
  height: 492px;
}

.itemDetailName {
  grid-area: detail-name;
  margin: 0;
  margin-left: 15px;
}

.itemDetailPrice {
  grid-area: detail-price;
  margin: 0;
  margin-left: 15px;
}

.itemDetailDescription {
  grid-area: detail-description;
  margin: 0;
  margin-left: 15px;
  height: fit-content;
}

.add-to-order-button {
  margin: 15px;
  background-color: black;
  color: white;
  height: 40px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
}

.item-quantity-select {
  background-color: #eeeeee;
  border-radius: 25px;
  padding: 5px;
  border: none;
  margin-left: 15px;
}

.item-quantity-select:hover {
  background-color: #dfdfdf;
  border: none;
}

.item-quantity-select:active {
  background-color: #cccbcb;
  border: none;
}

.quantity-option {
  background-color: #f7f7f7;
}

.add-to-order-button:hover {
  background-color: rgb(59, 59, 59);
}

.add-to-order-button:active {
  background-color: rgb(80, 80, 80);
}

.add-to-order-button:focus {
  border: none;
}

.back-to-restaurant {
  margin-bottom: 30px;
}

.back-to-restaurant:hover {
  cursor: pointer;
  text-decoration: underline;
}
