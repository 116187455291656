.manage-menu-item-table {
  /* border: 0.5px solid black; */
  margin: 20px;
}

.manage-menu-item-row {
  box-sizing: border-box;
  height: 100px;
}

.menu-item-table-data {
  border: 0.5px solid black;
  padding-left: 5px;
  padding-right: 5px;
  height: 100px;
}

#manage-menu--no-image {
  background-color: #eeeeee;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 95px;
}

.menu-item-image {
  max-width: 100px;
  max-height: 90px;
}

.mit-desc {
  height: 100px;
  overflow: hidden;
  text-overflow: auto;
}

.mit-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.mit-bttn:hover {
  background-color: #333333;
}

.mit-bttn:active {
  background-color: rgb(80, 80, 80);
}

.manage-menu--no-menu {
  display: flex;
  flex-direction: column;
}

.manage-menu--no-menu > span {
  margin-bottom: 10px;
}
