

.entireUpdateModal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:30px;
}

.updateModalButton{
    background-color: white;
    border: 2px solid black;
    color: black;
    font-weight: 550;
    padding: 15px;
}

.excludingTitle{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
