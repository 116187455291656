.delete--modal {
  height: 5rem;
  width: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.delete--modal-btn {
  display: flex;
  justify-content: space-between;
}

.delete--modal-btn > button {
  border: none;
  background-color: #000000;
  color: #ffffff;
  width: 10rem;
  margin-right: 10px;
  height: 2rem;
  border-radius: 10px;
}

.delete--modal-btn > button:hover {
  background-color: #333333;
}

.delete--modal-btn > button:active {
  background-color: #505050;
}
