.update--container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
}

.update--main {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.update--main-fields > input,
.update--main-fields > textarea,
.update--main-fields > select {
  width: 250px;
  border-radius: 10px;
  border: none;
  height: 40px;
  padding: 10px;
  background-color: #eeeeee;
}

.update--main-fields > textarea {
  height: 120px;
}

.update--main button {
  border-radius: 10px;
  border: none;
  height: 50px;
  padding: 10px;
  color: #ffffff;
  background-color: #000000;
}

.update--main button:hover {
  background-color: #333333;
}

.create--main button:active {
  background-color: #505050;
}

.update--main-inner {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.update--main-inner > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
