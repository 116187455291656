

.purchase{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 50px;
    font-family: 'Times New Roman', Times, serif;
}
