

.entireDeleteModal{
    display: flex;
    flex-direction: column;
    gap:30px;
}

.deleteReview{
    display: flex;
    justify-content: center;

}

.modalDeleteButtons{
    display: flex;
    justify-content: space-evenly;
}

.deleteModalButton{
    background-color: black;
    color: white;
    font-weight: bold;
    padding: 20px;
    border: 2px solid red;
}

.keepModalButton{
    color:black;
    padding: 20px;
    border: 2px solid black;
    background-color: white;

}
