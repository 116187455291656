/*All menu items for a restaurant*/
.all-menu-items--container {
  box-sizing: border-box;
  display: flex;
  padding: 0 40px;
  margin-top: 20px;
  margin-bottom: 30px;
  min-height: 450px;
}

.all-menu-items--container-empty {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.all-menu-items--container-empty span {
  padding: 5px;
}

.menu--sidebar {
  margin-top: 25px;
  font-weight: 540;
  min-width: 250px;
}

.menu--tiles {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.menu-ul {
  display: flex;
  flex-wrap: wrap;
  /* gap: 35px; */
  width: 100%;
}

.menu-li {
  flex: 0 0 calc(25% - 20px);
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 5px;
  height: fit-content;
}

.menu-li:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.25s ease-in-out 0s;
}

/* If there isn't a menu-tile-img, the icon should render */
.menu-tile-img {
  width: 100%;
  max-height: 161px;
  object-fit: cover;
  /* object-position: center; */
  padding-bottom: 3px;
}

#menu-tile--no-image {
  background-color: #eeeeee;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 243px;
  height: 161px;
}

/* ----------------------------------------------------- */

.menu-tile-name {
  overflow-wrap: break-word;
  margin: 0;
  padding-bottom: 3px;
  max-width: 243px;
}

/* Delete Menu Item Modal */
.delete-menu-item-container {
  height: 5rem;
  width: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.delete-menu-item-buttons {
  display: flex;
  justify-content: space-between;
}
.delete-menu-item-buttons > button {
  border: none;
  background-color: #000000;
  color: #ffffff;
  width: 10rem;
  margin-right: 10px;
  height: 2rem;
  border-radius: 10px;
}

.delete-menu-item-button:hover {
  background-color: #333333;
}

.delete-menu-item-button:active {
  background-color: #505050;
}
