.entireReviewSection button {
  background-color: black;
  color: white;
  border-radius: 2px;
  font-family: "Times New Roman", Times, serif;
  padding: 10px;
  border: 1px solid black;
}

.entireReviewSection{
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.noReviews{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
}

.name {
  font-weight: 510;
}

.posted {
  color: gray;
}

.review {
  color: gray;
}

.line {
  border-bottom: 1px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.card{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid black;
}

.reviewInformation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.buttonControl {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
}
.buttonControl1 {
  padding-right: 10px;
}

.postAction {
  padding-top: 10px;
  padding-bottom: 20px;
}

.withoutImage {
  padding-left: 20px;
}

.text {
  font-family: "Times New Roman", Times, serif;
  font-size: 70px;
}


/* @property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

.card {
  background: #191c29;
  width: 90%;
  height: 15vh;
  padding: 3px;
  position: relative;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: rgb(88 199 250 / 0%);
  cursor: pointer;
}

.card:hover {
  color: rgb(88 199 250 / 100%);
  transition: color 1s;
}
.card:hover:before,
.card:hover:after {
  animation: none;
  opacity: 0;
}

.card::before {
  content: "";
  width: 104%;
  height: 102%;
  border-radius: 8px;
  background-image: linear-gradient(
    var(--rotate),
    #5ddcff,
    #3c67e3 43%,
    #4e00c2
  );
  position: absolute;
  z-index: -1;
  top: -1%;
  left: -2%;
  animation: spin 2.5s linear infinite;
}

.card::after {
  position: absolute;
  content: "";
  top: calc(var(--card-height) / 6);
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.8);
  filter: blur(calc(var(--card-height) / 6));
  background-image: linear-gradient(
    var(--rotate),
    #5ddcff,
    #3c67e3 43%,
    #4e00c2
  );
  opacity: 1;
  transition: opacity 0.5s;
  animation: spin 2.5s linear infinite;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
} */

.entireReviewSection {
  margin-top: 5%;
}

.withoutColor {
  margin-top: 3%;
}
