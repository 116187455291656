.sb-res-tile-main {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #eeeeee;
  padding: 10px;
}

.sb-res-tile-main img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.sb-res-tile-info {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.res-closed {
  filter: brightness(40%);
}

.sb-res-tile-text {
  font-size: 14px;
  color: #9a9a9a;
}
