.itemFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
}
.menu-item-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menu-item-form > input,
select,
textarea {
  border-radius: 10px;
  border: none;
  height: 40px;
  padding: 10px;
  background-color: #eeeeee;
}

.menu-item-form > textarea {
  height: 50px;
  width: 400px;
}

.menu-item-form button {
  border-radius: 10px;
  border: none;
  height: 50px;
  padding: 10px;
  color: #ffffff;
  background-color: #000000;
}

.menu-item-form button:hover {
  background-color: #333333;
}

.menu-item-form button:active {
  background-color: #505050;
}
