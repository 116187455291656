.restaurant-tile--main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.restaurant-tile--main > h3 {
  line-break: anywhere;
}

.restaurant-tile--main > img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 10px;
}

.tile-close {
  filter: brightness(40%);
}

.restaurant-tile--main:hover {
  cursor: pointer;
}

.restaurant-tile--main p {
  font-size: 14px;
}

.restaurant-tile--delivery-info {
  color: #9a9a9a;
}
