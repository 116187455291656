.cartsidebar--container {
  background-color: #ffffff;
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(400px);
  transition: 0.5s ease;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 5;
}

.cartsidebar--container.open {
  transform: translate(0);
  box-shadow: -8px 10px 25px black;
}

.cartsidebar--container > p {
  font-size: 16px;
  line-break: strict;
  text-align: center;
}

.cartsidebar--container > button {
  border-radius: 20px;
  background-color: #000000;
  color: #ffffff;
  height: 30px;
  width: 120px;
}

.cartsidebar--cart-btn:hover {
  background-color: #333333;
}

.cartsidebar--cart-btn:active {
  background-color: #505050;
}

.cartsidebar--cart-img {
  width: 200px;
  height: 200px;
}

.cartsidebar--background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.5s ease;
}

.cartInformation {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid lightgrey;
}

.cartName {
  padding: 10px;
  width: 100px;
  overflow-wrap: break-word;
}

.cartAmount {
  padding: 10px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartPrice {
  padding: 10px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartImage {
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.cartHelp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cartButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.checkoutButton {
  background-color: black;
  color: white;
  margin-bottom: 10px;
  height: 50px;
  font-family: "Times New Roman", Times, serif;
  font-size: larger;
}

.addItemsButton {
  height: 50px;
  font-family: "Times New Roman", Times, serif;
  font-size: larger;
}

.entireCart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.topLineCart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid lightgrey;
}

.subtotalCart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
}

.item-quantity-select-Cart {
  border-radius: 10px;
  border: none;
  height: 40px;
  padding: 10px;
  background-color: #eeeeee;
  cursor: pointer;
}
