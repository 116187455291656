.signup--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup--main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;
  margin-bottom: 10px;
}

.signup--main input {
  border-radius: 10px;
  border: none;
  height: 50px;
  padding: 10px;
  background-color: #eeeeee;
}

.signup--main button {
  border-radius: 10px;
  border: none;
  height: 50px;
  padding: 10px;
  color: #ffffff;
  background-color: #000000;
}

.signup--main button:nth-child(-n + 5) {
  background-color: white;
  border: none;
  padding-bottom: 80px;
}

.signup--main button:hover {
  background-color: #333333;
}

.signup--main button:active {
  background-color: #505050;
}

.allImages {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 20px;
}

.icon-image {
  border-radius: 50%;
  width: 75px;
  height: 75px;
}

.signup--image-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.signup--image-container > h4 {
  font-weight: 500;
  color: #757575;
}

.signup--image-container [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.signup--image-container [type="radio"] + img {
  cursor: pointer;
}

.signup--image-container [type="radio"]:checked + img {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  outline: 2px solid red;
}
