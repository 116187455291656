.manager--main {
  display: flex;
  justify-content: flex-start;
}

.manager--navbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 300px;
  background-color: #f7f7f7;
  border-radius: 10px;
  height: 100vh;
  padding: 20px;
}

.manager--navbar a {
  color: black;
}

.manager--navbar-links li {
  background-color: #f7f7f7;
  font-size: 16px;
  padding: 5px;
}

.manager--navbar-links button {
  background-color: #f7f7f7;
  font-size: 16px;
  padding: 5px;
}

.manager--navbar-restaurants {
  display: none;
  opacity: 0;
  transition: opacity 0.5s, display 0.5s;
}

.manager--open {
  opacity: 1;
  display: block;
}

.caret-closed {
  transition: transform 500ms linear;
}

.caret-open {
  transform: rotate(180deg);
  transition: transform 500ms linear;
}

.manager--routes {
  width: 100%;
}
